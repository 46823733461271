import PropTypes from "prop-types";
import { t } from "@/i18n-js/instance";
import {
  FIELD_TYPES,
  NUMERIC_FORMATS,
} from "@circle-react/components/SettingsApp/ProfileFields/constants";
import { getPlaceholderPreview } from "../CustomField/NumberInput";

const getAffix = numberOptions => {
  const { format, position, text } = numberOptions;
  if (format !== NUMERIC_FORMATS.CUSTOM) {
    return { prefix: "", suffix: "" };
  }

  const prefix = position === "left" ? `${text} ` : "";
  const suffix = position === "right" ? ` ${text}` : "";
  return { prefix, suffix };
};

const NumberPreview = ({ number_options: numberOptions }) => {
  const placeholder = getPlaceholderPreview({ numberOptions });
  const percentSign =
    numberOptions.format === NUMERIC_FORMATS.PERCENT ? "%" : "";
  const { prefix, suffix } = getAffix(numberOptions);
  return `${prefix}${placeholder}${percentSign}${suffix}`;
};

const CheckboxPreview = ({ choices }) => (
  <span className="flex flex-col gap-y-2">
    {choices.map(choice => (
      <span key={choice.id}>{choice.value}</span>
    ))}
  </span>
);

CheckboxPreview.propTypes = {
  choices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      value: PropTypes.string,
    }),
  ),
};

export const FieldTypePreviewInformation = {
  [FIELD_TYPES.TEXT]: () => "-",
  [FIELD_TYPES.LINK]: () => t("profile_fields.form.default_link"),
  [FIELD_TYPES.SELECT]: () => t("profile_fields.form.default_select_option"),
  [FIELD_TYPES.NUMBER]: NumberPreview,
  [FIELD_TYPES.CHECKBOX]: CheckboxPreview,
  [FIELD_TYPES.TEXTAREA]: () => "-",
  [FIELD_TYPES.LOCATION]: () => "-",
};
